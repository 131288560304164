<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.7562 7.56353L6.95555 4.01287C6.68689 3.7622 6.26422 3.77553 6.01356 4.04487C5.76222 4.3142 5.77622 4.7362 6.04489 4.98753L9.80556 8.5002L6.04489 12.0129C5.77622 12.2642 5.76156 12.6862 6.01289 12.9555C6.14422 13.0962 6.32156 13.1669 6.50022 13.1669C6.66356 13.1669 6.82689 13.1075 6.95555 12.9875L10.7562 9.43687C11.0209 9.18887 11.1669 8.85687 11.1669 8.5002C11.1669 8.14353 11.0209 7.81153 10.7562 7.56353Z"
      fill="#9CA3AF"
    />
  </svg>
</template>
