<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.75 9C21.7504 8.93027 21.7409 8.86083 21.7219 8.79375L20.3766 4.0875C20.2861 3.77523 20.0971 3.50059 19.8378 3.30459C19.5784 3.10858 19.2626 3.00174 18.9375 3H5.0625C4.73741 3.00174 4.4216 3.10858 4.16223 3.30459C3.90287 3.50059 3.71386 3.77523 3.62344 4.0875L2.27906 8.79375C2.2597 8.86079 2.24991 8.93022 2.25 9V10.5C2.25 11.0822 2.38554 11.6563 2.6459 12.1771C2.90625 12.6978 3.28427 13.1507 3.75 13.5V19.5C3.75 19.8978 3.90804 20.2794 4.18934 20.5607C4.47064 20.842 4.85218 21 5.25 21H18.75C19.1478 21 19.5294 20.842 19.8107 20.5607C20.092 20.2794 20.25 19.8978 20.25 19.5V13.5C20.7157 13.1507 21.0937 12.6978 21.3541 12.1771C21.6145 11.6563 21.75 11.0822 21.75 10.5V9ZM8.25 10.5C8.24986 10.8869 8.14996 11.2673 7.95993 11.6043C7.7699 11.9413 7.49617 12.2236 7.16518 12.424C6.83419 12.6244 6.45713 12.736 6.07041 12.7481C5.68368 12.7602 5.30037 12.6724 4.9575 12.4931C4.90533 12.4525 4.84794 12.4191 4.78688 12.3937C4.46913 12.1903 4.20764 11.9103 4.02645 11.5794C3.84527 11.2484 3.7502 10.8773 3.75 10.5V9.75H8.25V10.5ZM14.25 10.5C14.25 11.0967 14.0129 11.669 13.591 12.091C13.169 12.5129 12.5967 12.75 12 12.75C11.4033 12.75 10.831 12.5129 10.409 12.091C9.98705 11.669 9.75 11.0967 9.75 10.5V9.75H14.25V10.5ZM20.25 10.5C20.2497 10.8774 20.1545 11.2486 19.9731 11.5795C19.7918 11.9104 19.5301 12.1904 19.2122 12.3937C19.1519 12.4191 19.0952 12.4522 19.0434 12.4922C18.7006 12.6716 18.3173 12.7596 17.9305 12.7477C17.5437 12.7357 17.1666 12.6242 16.8355 12.424C16.5044 12.2237 16.2305 11.9414 16.0404 11.6044C15.8502 11.2673 15.7502 10.887 15.75 10.5V9.75H20.25V10.5Z"
      fill="currentColor"
    />
  </svg>
</template>
