<script setup lang="ts">
import { ref, watch, defineProps, defineEmits } from "vue";
import { useRouter } from "vue-router";
import { useMerchantStore } from "@/stores/merchant";

const profile = computed(() => useMerchantStore().profile);
const router = useRouter();
const props = defineProps({
  isShowModal: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:isShowModal"]);

const localIsShowModal = ref(props.isShowModal);

watch(
  () => props.isShowModal,
  (newVal) => {
    localIsShowModal.value = newVal;
  },
);

const handleRegister = () => {
  const url = !profile.value?.email_verified_at
    ? "/email-verification"
    : "/completing/step-1";
  handleCloseModal();
  router.push(url);
};

const handleCloseModal = () => {
  localIsShowModal.value = false;
  emit("update:isShowModal", false);
};
</script>

<template>
  <Modal
    :is-modal-show="localIsShowModal"
    size="xs"
    without-border
    save-text="Daftar"
    full-width
    border-blue
    @close-modal="handleCloseModal"
    @save-action="handleRegister"
  >
    <template #cancelButton>
      <a
        class="flex w-full items-center justify-center"
        href="https://calendly.com/desmount-i7g/flikintro"
        target="_blank"
      >
        Book Demo
      </a>
    </template>
    <template #body>
      <div class="-mt-6">
        <img src="/images/cross-selling.png" alt="Cross Selling" />
      </div>
      <div class="text-center font-sans">
        <div class="text-base font-bold text-gray-900">
          Tertarik FLIK Checkout
        </div>
        <div class="text-sm font-normal text-gray-500">
          Pelajari fitur lengkapnya <br />
          untuk jualan lebih praktis
        </div>
      </div>
    </template>
  </Modal>
</template>
